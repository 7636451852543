import styled, { css, keyframes } from 'styled-components'
import { useTheme } from '@/contexts/Theme'

const gradient = keyframes`
  0% {
    background-position: -50vw 0;
  }
  80% {
    background-position: 50vw 0;
  }

  100% {
    background-position: 50vw 0;
  }
`

export const StyledSkeleton = styled.div<{
  width?: number
  height?: number
  marginTop?: number
  avatar?: boolean
  dark?: boolean
}>`
  animation: ${gradient} 1s linear infinite;
  background: var(--grey-100);
  background: ${(p) =>
    p.dark
      ? 'var(--neutral-3)'
      : `linear-gradient(
    to right,
    var(--grey-100) 5%,
    var(--grey-200) 15%,
    var(--grey-100) 25%
  )`};
  display: inline-block;
  border-radius: ${(p) => (p.avatar ? '50%' : '4px')};
  width: ${(p) => (p.width ? `${p.width}px` : '100%')};
  height: ${(p) => (p.height ? `${p.height}px` : '100%')};
  background-size: 100vw 100vh;
  ${(p) =>
    p.marginTop &&
    css`
      margin-top: ${p.marginTop}px;
    `}
`
type SkeletonProps = {
  width?: number
  height?: number
  marginTop?: number
  avatar?: boolean
}

export const Skeleton = ({
  width,
  height,
  marginTop,
  avatar,
}: SkeletonProps) => {
  const { theme } = useTheme()
  return (
    <StyledSkeleton
      dark={theme === 'dark'}
      width={width}
      height={height}
      marginTop={marginTop}
      avatar={avatar}
    />
  )
}
